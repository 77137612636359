<template>
  <div>
    <form role="form" @submit="onFormSubmit" name="update-module">
      <div class="row">
        <div class="col-lg-12">
          <h3>{{ getLocalizedText(labels.title) }}</h3>
        </div>
      </div>
      <TextField 
          ref="updateName"
          v-bind:value.sync="moduleForm.name" 
          id="updateName" 
          :label="getLocalizedText(labels.module.name)" 
          :placeholder="getLocalizedText(labels.module.name_placeholder)" 
          :labelAsColumn="true"
          :required="true"
      />
      <TextField 
          ref="updateCode"
          v-bind:value.sync="moduleForm.code" 
          id="updateCode" 
          :label="getLocalizedText(labels.module.code)" 
          :placeholder="getLocalizedText(labels.module.code_placeholder)" 
          :labelAsColumn="true"
          :required="true"
      />
      <SwitchCheck 
            ref="updateIsActivated"
            v-bind:value.sync="moduleForm.isActivated" 
            id="updateIsActivated" 
            :label="getLocalizedText(labels.module.isActivated)" 
            :labelAsColumn="true"
        />
      <button class="btn btn-primary ladda-button update" data-style="zoom-in" type="submit">{{ getLocalizedText(labels.button) }}</button>
    </form>

  </div>

</template>

<style>

</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';

import { GenericModuleAdminInput, useGenericModuleAdmin } from "@root/src/client/composables/admin/useGenericModuleAdmin";

export default defineComponent({
  props: {
      module: {
        type: Object,
        required: true
      },
      genericModuleInput: {
        type: Object as PropType<GenericModuleAdminInput>,
        required: true
      }
  },
  components: {
      TextField, SwitchCheck
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();
    
    const { modules, updateModule, updateModuleFormForUpdate, moduleForm } = useGenericModuleAdmin({
      ...props.genericModuleInput
    }, context);

    const { module, genericModuleInput } = toRefs(props);

    const labels = {
      title: {
        fr: "Modifier",
        en: "Update"
      },
      module : {
        name: {
          fr: "Nom",
          en: "Name"
        },
        name_placeholder: {
          fr: "Nom du module",
          en: "Module name"
        },
        code: {
          fr: "Code",
          en: "Code"
        },
        code_placeholder: {
          fr: "Code du module",
          en: "Module code"
        },
        isActivated: {
          fr: "Activé",
          en: "Activated"
        }
      },
      button: {
        fr: "Mettre à jour",
        en: "Update"
      }
    }

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-module] button.update' );
      laddaSubmit = Ladda.create(submitButton!);
    })

    updateModuleFormForUpdate(module.value);

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();
      updateModule().then((result:any) => {
        if(result.updated) {
          context.emit('module-updated', result.module);
        }
        laddaSubmit!.stop();
      })
    }

    // We watch if we have site ID in URL
    watch(
      module,
      (val:any, oldVal:any) => {
        updateModuleFormForUpdate(module.value);
      },
      { deep: true }
    )

    return {
      onFormSubmit,
      moduleForm,
      labels
    }
  }
})
</script>