<template>
  <div class="admin-settings-form">
    <form role="form" @submit="onFormSubmit" name="update-admin-settings">
      <div class="row">
        <div class="col-lg-12">
          <h3>{{ getLocalizedText(labels.title) }}</h3>
        </div>
      </div>
      <Select
        v-if="dropboxAccounts.length > 0"
        :label="getLocalizedText(labels.dropboxStorageAccount)"
        :placeholder="getLocalizedText(labels.dropboxStorageAccount_placeholder)"
        :selectOptions="{
          multiple: false,
          options: dropboxAccounts
        }"
        v-bind:value.sync="form.dropboxAccount" 
        :labelAsColumn="true"
      />
      <Select
        v-if="vatValidationModules.length > 0"
        :label="getLocalizedText(labels.vatValidationModule)"
        :placeholder="getLocalizedText(labels.vatValidationModule_placeholder)"
        :selectOptions="{
          multiple: false,
          options: vatValidationModules,
          getOptionLabel : option => option.name,
          reduce: option => option._id
        }"
        v-bind:value.sync="form.vatValidationModuleID" 
        :labelAsColumn="true"
      />
      <button class="btn btn-primary ladda-button update" data-style="zoom-in" type="submit">{{ getLocalizedText(labels.button) }}</button>
    </form>
  </div>
</template>

<style>
  
</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';

import Select from '@fwk-client/components/panels/input/Select.vue';

import { useModuleAdmin } from '../../../../composables/useModuleAdmin';

export default defineComponent({
  props: {
    module: {
        type: Object,
        required: true
      }
  },
  components: {
    Select
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { getStorageAccounts, getAdminSettings, updateAdminSettings, getVATValidationModules } = useModuleAdmin(props, context);

    const availableLanguageCodes = ['fr','en'];

    const { module } = toRefs(props);

    const adminSettings:Ref<any> = ref({});
    const form:Ref<any> = ref({});
    getAdminSettings(module.value).then((result:any) => {
      adminSettings.value = result.adminSettings;
      updateFormFromAdminSettings(result.adminSettings);
    });

    const dropboxAccounts:Ref<any[]> = ref([]);
    getStorageAccounts(module.value).then((result:any) => {
      dropboxAccounts.value = result.storageAccounts;
    });

    const vatValidationModules:Ref<any[]> = ref([]);
    getVATValidationModules(module.value).then((result:any) => {
      vatValidationModules.value = result.vatValidationModules;
    });

    const labels = {
      "title": {
        "fr" : "Paramètres",
        "en" : "Settings"
      },
      "dropboxStorageAccount" : {
        "fr" : "Compte Dropbox",
        "en" : "Dropbox Account"
      },
      "dropboxStorageAccount_placeholder" : {
        "fr" : "Sélectionnez le compte",
        "en" : "Select account"
      },
      "vatValidationModule" : {
        "fr" : "Module de validation de TVA",
        "en" : "VAT Validation Module"
      },
      "vatValidationModule_placeholder" : {
        "fr" : "Sélectionnez le module",
        "en" : "Select module"
      },
      "button" : {
        "fr" : "Mettre à jour",
        "en" : "Update"
      }
    }

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-admin-settings] button.update' );
      laddaSubmit = Ladda.create(submitButton!);
    })

    const updateFormFromAdminSettings = (settings:any) => {
      form.value = {
        // DROPBOX
        dropboxAccount : (settings && settings.storage) ? settings.storage.account : undefined,
        // VAT VALIDATION
        vatValidationModuleID : (settings && settings.vatValidationModuleID) ? settings.vatValidationModuleID : undefined
      };
    }

    const getSettingsFormForm = () => {
      let settings = {
        storage: form.value.dropboxAccount ? {
            type: "DROPBOX",
            account: form.value.dropboxAccount
        } : undefined,
        vatValidationModuleID: form.value.vatValidationModuleID ? form.value.vatValidationModuleID : undefined
      }
      return settings;
    }

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      let settingsInput = getSettingsFormForm();
      laddaSubmit!.start();
      updateAdminSettings(module.value, settingsInput).then((result:any) => {
        if(result.updated) {
          context.emit('module-updated', result.module);
        }
        laddaSubmit!.stop();
      })
    }

    return {
      form,
      labels,
      dropboxAccounts,
      vatValidationModules,
      onFormSubmit
    }
  }  
})
</script>