<template>
  
    <form role="form" @submit="onFormSubmit" name="create-module">
        <TextField 
          ref="createName"
          v-bind:value.sync="moduleForm.name" 
          id="createName" 
          :label="getLocalizedText(labels.module.name)" 
          :placeholder="getLocalizedText(labels.module.name_placeholder)" 
          :labelAsColumn="true"
          :required="true"
        />
        <TextField 
          ref="createCode"
          v-bind:value.sync="moduleForm.code" 
          id="createCode" 
          :label="getLocalizedText(labels.module.code)" 
          :placeholder="getLocalizedText(labels.module.code_placeholder)" 
          :labelAsColumn="true"
          :required="true"
        />
        <SwitchCheck 
            ref="updateIsActivated"
            v-bind:value.sync="moduleForm.isActivated" 
            id="updateIsActivated" 
            :label="getLocalizedText(labels.module.isActivated)" 
            :labelAsColumn="true"
        />
        <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{ getLocalizedText(labels.button) }}</button>
    </form>

</template>


<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';

import { GenericModuleAdminInput, useGenericModuleAdmin } from "@root/src/client/composables/admin/useGenericModuleAdmin";

export default defineComponent({
  props: {
      genericModuleInput: {
        type: Object as PropType<GenericModuleAdminInput>,
        required: true
      }
  },
  components: {
      TextField,
      SwitchCheck
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();
    
    const { moduleForm, createModule } = useGenericModuleAdmin({
      ...props.genericModuleInput
    }, context);

    const labels = {
      module: {
        name: {
          fr: "Nom",
          en: "Name"
        },
        name_placeholder: {
          fr: "Nom du module",
          en: "Module name"
        },
        code: {
          fr: "Code",
          en: "Code"
        },
        code_placeholder: {
          fr: "Code du module",
          en: "Module code"
        },
        isActivated: {
          fr: "Activé",
          en: "Activated"
        }
      },
      button: {
        fr: "Créer le module",
        en: "Create the module"
      }
    }

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var button:HTMLButtonElement|null = document.querySelector( 'form[name=create-module] button' );
      laddaSubmit = Ladda.create(button!);
    })

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();
      createModule().then((result:any) => {
        if(result.created) {  
          context.emit('module-created', result.module);
        }
        laddaSubmit!.stop();
      })
    }

    return {
      onFormSubmit,
      moduleForm,
      labels
    }
  }
})
</script>