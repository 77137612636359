<template>
  <div class="ibox modules">
    <div class="ibox-title">
      <h2>{{ getLocalizedText(labels.title) }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</p>
    
      <b-table 
          outlined striped
          :items="modules"
          :fields="listFields"
          ref="listItems">
        <template v-slot:cell(select)="row">
          <p-check class="p-default p-curve p-primary" color="success" :value="row.item.module._id" v-model="listItemsSelected"></p-check>
        </template>
        <template v-slot:cell(options)="row">
          <span v-if="isModuleUpdateAllowed">
            <a href="javascript:void(0)" @click="updateForm(row.item.module)">{{ getLocalizedText(labels.options.update) }}</a>
            /
          </span>
          <span v-if="isModuleRemoveAllowed">
            <a href="javascript:void(0)" @click="confirmRemoveItem(row.item.module)">{{ getLocalizedText(labels.options.delete) }}</a>
            /
          </span>
        </template>
      </b-table>

      <button v-if="isModuleCreateAllowed" class="btn btn-primary" @click="showCreateItemModal()">{{ getLocalizedText(labels.buttons.create) }}</button>

      <b-modal size="xl" ref="updateItemModal" :title="getLocalizedText(labels.updateTitle, {params:[itemToUpdate.name]})" hide-footer lazy>
        <UpdateModule :genericModuleInput="genericModuleInput" :module="itemToUpdate" v-on:module-updated="onItemUpdated" />
        <slot name="adminSettingsForm" :module="itemToUpdate" :onModuleUpdated="onItemUpdated"></slot>
      </b-modal>

      <b-modal size="xl" ref="createItemModal" :title="getLocalizedText(labels.createTitle)" hide-footer lazy>
        <CreateModule :genericModuleInput="genericModuleInput" v-on:module-created="onItemCreated" />
      </b-modal>

      <b-modal ref="removeItemModal" 
          hide-header
          @ok="removeItem">
        {{ getLocalizedText(labels.buttons.deleteConfirmation, {params:[itemToRemove.name]}) }}
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
  
</style>



<script lang="ts">
import { Ref, defineComponent, ComputedRef, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, toRefs } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import UpdateModule from './Update.vue';
import CreateModule from './Create.vue';

import { languagesTypes } from '@fwk-client/store/types';

import { GenericModuleAdminInput, useGenericModuleAdmin } from "@root/src/client/composables/admin/useGenericModuleAdmin";


export default defineComponent({
  props: {
    genericModuleInput: {
        type: Object as PropType<GenericModuleAdminInput>,
        required: true
      }
  },
  components: {
    UpdateModule, CreateModule
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { modules, selectModule, isModuleUpdateAllowed, isModuleCreateAllowed, isModuleRemoveAllowed, isListLoading, removeModule } = useGenericModuleAdmin({
      ...props.genericModuleInput
    }, context);

    const { genericModuleInput } = toRefs(props);

    const listItems:Ref<HTMLElement|null> = ref(null);
    const createItemModal:Ref<HTMLElement|null> = ref(null);
    const removeItemModal:Ref<HTMLElement|null> = ref(null);
    const updateItemModal:Ref<HTMLElement|null> = ref(null);

    const isMultiSelectAllowed:Ref<boolean> = ref(false);
    const showNumberOfRows:Ref<boolean> = ref(false);

    const numberOfRows:Ref<number> = computed(() => {
        return modules.value.length
    })

    const itemToRemove:Ref<any> = ref({
    });
    const itemToUpdate:Ref<any> = ref({
    });

    
    const listLoading:Ref<boolean> = computed(() => {
      return isListLoading.value || removeLoading.value;
    })

    const listItemsSelected:Ref<any[]> = ref([]);

    const listFields:Ref<any[]> = ref([
      {
        key: "select",
        label: "",
        class: (isMultiSelectAllowed.value ? "" : "hidden"),
      },
      {
        key: "module.name",
        label: "",
      },
      {
        key: "options",
        label: "",
      }
    ]);

    const labels = {
      title: {
        fr: "Liste des modules",
        en: "Modules list"
      },
      headerName: {
        fr: "Nom",
        en: "Name"
      },
      headerOptions: {
        fr: "Options",
        en: "Options"
      },
      options: {
        update: {
          fr: "Modifier",
          en: "Update"
        },
        delete: {
          fr: "Supprimer",
          en: "Delete"
        }
      },
      buttons: {
        create: {
          fr: "Créer",
          en: "Create"
        },
        deleteConfirmation: {
          fr: "Voulez-vous vraiment supprimer le module ({0}) ?",
          en: "Do you really want to delete the module ({0}) ?"
        }
      },
      updateTitle: {
        fr: "Modifier le module ({0})",
        en: "Update module ({0})"
      },
      createTitle: {
        fr: "Créer un module",
        en: "Create a module"
      }
    }

    onMounted(() => {
      updateTableLabels();
    })

    const updateTableLabels = () => {
      listFields.value[1].label = app.getLocalizedText(labels.headerName) as string;
      listFields.value[2].label = app.getLocalizedText(labels.headerOptions) as string;
    }

    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const onItemCreated = () => {
      // @ts-ignore
      createItemModal.value.hide()
    }

    const onItemUpdated = (item:any) => {
      itemToUpdate.value = item;
    }

    const confirmRemoveItem = (item:any) => {
      itemToRemove.value = item;
      // @ts-ignore
      removeItemModal.value.show()
    }

    const updateForm = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      updateItemModal.value.show()
    }

    const showCreateItemModal = () => {
      // @ts-ignore
      createItemModal.value.show()
    }

    const removeLoading:Ref<boolean> = ref(false);
    const removeItem = () => {

      removeLoading.value = true;
      removeModule(itemToRemove.value._id).then((removed:boolean) => {
        // We reset the user to remove
        itemToRemove.value = {};

        removeLoading.value = false;
      })
    }

    watch(
      currentLanguageCode,
      (val:any, oldVal:any) => {
       updateTableLabels();
      },
      { deep: false }
    )

    return {
      listLoading,
      showNumberOfRows,
      numberOfRows,
      modules,
      listItems,
      listFields,
      listItemsSelected,
      isModuleUpdateAllowed,
      updateForm,
      isModuleRemoveAllowed,
      confirmRemoveItem,
      isModuleCreateAllowed,
      showCreateItemModal,
      updateItemModal,
      itemToUpdate,
      onItemUpdated,
      createItemModal,
      onItemCreated,
      removeItemModal,
      removeItem,
      itemToRemove,
      genericModuleInput,
      labels
    }
  }
})
</script>