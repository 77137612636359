var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"admin-settings-form"},[_c('form',{attrs:{"role":"form","name":"update-admin-settings"},on:{"submit":_vm.onFormSubmit}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('h3',[_vm._v(_vm._s(_vm.getLocalizedText(_vm.labels.title)))])])]),_vm._v(" "),(_vm.dropboxAccounts.length > 0)?_c('Select',{attrs:{"label":_vm.getLocalizedText(_vm.labels.dropboxStorageAccount),"placeholder":_vm.getLocalizedText(_vm.labels.dropboxStorageAccount_placeholder),"selectOptions":{
        multiple: false,
        options: _vm.dropboxAccounts
      },"value":_vm.form.dropboxAccount,"labelAsColumn":true},on:{"update:value":function($event){return _vm.$set(_vm.form, "dropboxAccount", $event)}}}):_vm._e(),_vm._v(" "),(_vm.vatValidationModules.length > 0)?_c('Select',{attrs:{"label":_vm.getLocalizedText(_vm.labels.vatValidationModule),"placeholder":_vm.getLocalizedText(_vm.labels.vatValidationModule_placeholder),"selectOptions":{
        multiple: false,
        options: _vm.vatValidationModules,
        getOptionLabel : option => option.name,
        reduce: option => option._id
      },"value":_vm.form.vatValidationModuleID,"labelAsColumn":true},on:{"update:value":function($event){return _vm.$set(_vm.form, "vatValidationModuleID", $event)}}}):_vm._e(),_vm._v(" "),_c('button',{staticClass:"btn btn-primary ladda-button update",attrs:{"data-style":"zoom-in","type":"submit"}},[_vm._v(_vm._s(_vm.getLocalizedText(_vm.labels.button)))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }