<template>
  
  <div class="ibox">
    <div class="ibox-title">
      <h2>Copy Module</h2>
    </div>
    <div :class="'ibox-content p-md'+ (panelLoading ? ' sk-loading' : '')">

      <div v-if="panelLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <form role="form" @submit="onFormSubmit" name="copyModuleForm">
        <div class="form-group row">
          <div class="col">
            <v-select :options="listDBs" :getOptionLabel="listDBsOptionLabel" v-model="form.originDB" placeholder="Select an origin DB" @keypress.enter.native.prevent="">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.originDB"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>
          <div class="col">
            <v-select :options="listModules" v-model="form.module" :getOptionLabel="option => option.module.name" placeholder="Select a module" @keypress.enter.native.prevent="">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.module"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <v-select :options="listDBs" :getOptionLabel="listDBsOptionLabel" v-model="form.destinationDB" placeholder="Select a destination DB" @keypress.enter.native.prevent="">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.destinationDB"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>
          <div class="col">
            <!-- TODO: Add modules options depending on module types: ex: includeApartments -->
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">Copy</button>
          </div>
        </div>
      </form>

      

    </div>
  </div>
        
</template>

<style scoped>
  
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, toRefs } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as api from '@fwk-client/utils/api';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';

export default defineComponent({
  props: {
      apiModulePath: {
        type: String,
        required: true
      }
  },
  components: {
      SwitchCheck
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { apiModulePath } = toRefs(props);

    const form:any = reactive({
      module:"",
      originDB:"",
      destinationDB:""
    });    

    const listDBs:Ref<any[]> = ref([]);
    const listModules:Ref<any[]> = ref([]);

    const listPathPrefix:any[] = [
      "dev-staging",
      "dev-production",
      "staging",
      "production"
    ];

    const panelLoading:Ref<boolean> = ref(false);

    const onFormSubmit = (evt:Event) => {
      evt.preventDefault();

      var options:api.ApiVueOptions =  {
        app: app
      }

      var input = {
        moduleID : form.module.module._id,
        originDB : form.originDB.title,
        destinationDB : form.destinationDB.title
      }

      panelLoading.value = true;

      return api.postAPI(apiModulePath.value + '/modules/copy', input,  options).then((response:any) => {
        panelLoading.value = false;
      })
    }

    const updateListModules = () => {
      // We need to get the list of available companies for the current logged in user
      var options:api.ApiVueOptions =  {
        app: app
      }

      var input = {
        targetDB : form.originDB.title
      }

      return api.postAPI(apiModulePath.value + '/modules/list', input, options).then((response:any) => {
        if(response.modules) {  
          listModules.value = response.modules;
        }
        else {
          listModules.value = [];
        }
      });
    }

    const updateListDBs = () => {
      // We need to get the list of available companies for the current logged in user
      var options:api.ApiVueOptions =  {
        app: app
      }
      panelLoading.value = true;
      api.getAPI('/api/admin/database/configs', options).then((response:any) => {
        if(response.configs) {  
          listDBs.value = response.configs;
        }
        else {
          listDBs.value = [];
        }
        panelLoading.value = false;
      });
    }
    updateListDBs();

    const listDBsOptionLabel = (option:any) => {
      return option.title;
    }

    watch(
      () => form.originDB,
      (val:any, oldVal:any) => {
        if(val == null) {
          listModules.value = [];
        }
        else {
          updateListModules();
        }
      },
      { deep: true }
    )

    return {
      onFormSubmit,
      panelLoading,
      form,
      listDBs,
      listDBsOptionLabel,
      listModules
    }
  }
})
</script>